import { Typography, useTheme } from '@mui/material';
import { FileDetailsWrapper, UploadedFileWrapper } from './UploadedFile.css';
import { IUploadedFile } from './UploadedFile.types';
import humanFileSize from './UploadedFile.utils';
import IconButton from '../../IconButton/IconButton';
import {
  RiDeleteBin7Line,
  RiDownload2Line,
  RiFileZipLine,
  RiImage2Line,
} from '@remixicon/react';
import { useIntl } from 'react-intl';
import CircularSpinner from '../../CircularSpinner/CircularSpinner';

export default function UploadedFile({
  error,
  errorMessage,
  file,
  onClickDelete,
  onClickDownload,
  pendingValidation,
}: IUploadedFile) {
  const theme = useTheme();

  const intl = useIntl();

  const helperText =
    error && errorMessage
      ? errorMessage
      : pendingValidation
        ? intl.formatMessage({
            id: 'customer-platform.shared.components.forms.listing-form.filepicker.validation-pending',
          })
        : file.size
          ? humanFileSize(file.size)
          : undefined;

  return (
    <UploadedFileWrapper>
      {pendingValidation ? (
        <CircularSpinner />
      ) : file.type === 'zip' || file.type === 'application/zip' ? (
        <RiFileZipLine
          size={24}
          color={!error ? theme.palette.neutral[400] : theme.palette.error[500]}
        />
      ) : (
        <RiImage2Line
          size={24}
          color={!error ? theme.palette.neutral[400] : theme.palette.error[500]}
        />
      )}

      <FileDetailsWrapper>
        <Typography
          variant="p1"
          color={!error ? theme.palette.neutral[500] : theme.palette.error[500]}
        >
          {file.name}
        </Typography>

        {helperText && (
          <Typography
            variant="p3"
            color={
              !error ? theme.palette.neutral[500] : theme.palette.error[500]
            }
          >
            {helperText}
          </Typography>
        )}
      </FileDetailsWrapper>

      {onClickDownload && (
        <IconButton
          data-testid="filepicker.icon-button.download"
          id="filepicker.icon-button.download"
          onClick={onClickDownload}
        >
          <RiDownload2Line size={24} color={theme.palette.neutral[500]} />
        </IconButton>
      )}
      {!error && (
        <IconButton
          data-testid="filepicker.icon-button.delete"
          id="filepicker.icon-button.delete"
          onClick={onClickDelete}
        >
          <RiDeleteBin7Line size={24} color={theme.palette.neutral[500]} />
        </IconButton>
      )}
    </UploadedFileWrapper>
  );
}
