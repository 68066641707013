import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import {
  ISellerOrderPhotoValidationSubmissionData,
  ISellerOrderPhotoValidationError,
} from './validatePhotoContents.types';
import axiosClient from '../axiosClient';

const validateFile = (apiUrl: string, file: File, orderId: string) => {
  const uploadForm = new FormData();
  uploadForm.append('sellerOrderPhotos', file, file.name);
  uploadForm.append('validation_only', 'true');
  return axiosClient.post(
    `${apiUrl}/orders/seller/${orderId}/photos/upload/`,
    uploadForm,
  );
};

const useValidateSellerOrderFiles = (orderId: string) => {
  const { apiUrl } = useContext(ApiContext);
  return useMutation<
    AxiosResponse,
    AxiosError<ISellerOrderPhotoValidationError>,
    ISellerOrderPhotoValidationSubmissionData
  >({
    mutationFn: ({ sellerOrderPhoto }) => {
      return validateFile(apiUrl, sellerOrderPhoto, orderId);
    },
  });
};

export default useValidateSellerOrderFiles;
