import {
  ISellerOrderFileUploadErrorObject,
  ISellerOrderFileUploadSubmissionData,
  ISellerOrderFileUploadSubmissionDataKeys,
  useValidateSellerOrderPhotos,
} from '@frontend/api';
import { Translate } from '@frontend/translation';
import {
  Button,
  FilePicker,
  humanFileSize,
  IFilePickerFile,
} from '@frontend/ui-elements';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, useTheme } from '@mui/material';
import { RiArrowDownSFill } from '@remixicon/react';
import { head } from 'lodash';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  FieldError,
  FieldErrors,
  useController,
  useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import {
  FileManagerButtonsWrapper,
  FileManagerFormWrapper,
  FileManagerWrapper,
  FileManagerGlobalErrorWrapper,
} from './FileManagerForm.css';
import {
  FILE_MANAGER_FORMATS_VALIDABLE,
  FILE_MANAGER_MAX_FILE_SIZE,
  FILE_MANAGER_SUPPORTED_FORMATS,
} from './FileManagerForm.const';
import { FileManagerFormRef, FileManagerProps } from './FileManagerForm.types';
import { PhotoValidationStatus, getFileFootprint } from '@frontend/ui-elements';
import getFileManagerFormSchema from './FileManagerForm.utils';
import FormType from '../utils/FormType.types';
import { useFormTracking } from '../utils/useFormTracking';
import { FEATURE_FLAGS } from '@frontend/utils';

const FileManagerForm = forwardRef<FileManagerFormRef, FileManagerProps>(
  (
    {
      alreadyUploadedFiles,
      deleteList,
      handleDeleteList,
      errors,
      globalError,
      isSuccess,
      isPending,
      hasUnsavedChanges,
      fileVariant,
      onDownloadSingle,
      onDownloadAll,
      onSubmit,
      orderId,
    }: FileManagerProps,
    ref,
  ) => {
    const [invalidFiles, setInvalidFiles] = useState<IFilePickerFile[]>([]);
    const [showSubmitButton, setShowSubmitButton] = useState<boolean>(false);
    const [validationStatus, setValidationStatus] = useState<
      Map<string, PhotoValidationStatus>
    >(new Map());
    const intl = useIntl();
    const theme = useTheme();
    const formId = `file-manager.${fileVariant}-form`;

    const dragAndDropText = () => {
      return (
        <Translate
          id={`customer-platform.listing-details.order-${fileVariant}.file-manager.drag-and-drop.text`}
          values={{
            span: (chunks: string[]) => (
              <Typography
                color={theme.palette.neutral[500]}
                component="span"
                variant="p1"
              >
                {head(chunks)}
                <RiArrowDownSFill
                  color={theme.palette.neutral[500]}
                  size={16}
                />
              </Typography>
            ),
          }}
        />
      );
    };

    const dragAndDropActiveText = () => {
      return (
        <Translate
          id={`customer-platform.listing-details.order-${fileVariant}.file-manager.drag-and-drop.active-text`}
        />
      );
    };

    const fileSize = intl.formatMessage(
      {
        id: 'customer-platform.shared.components.forms.errors.filepicker.file_size',
      },
      { MAX_FILE_SIZE: humanFileSize(FILE_MANAGER_MAX_FILE_SIZE) },
    );
    const fileType = intl.formatMessage(
      {
        id: 'customer-platform.shared.components.forms.errors.filepicker.file_type',
      },
      {
        SUPPORTED_FORMATS: Object.keys(FILE_MANAGER_SUPPORTED_FORMATS)
          .join(', ')
          .toUpperCase(),
      },
    );

    const fileManagerFormSchema = getFileManagerFormSchema({
      fileSize,
      fileType,
    });

    const {
      control,
      formState: { errors: formErrors },
      handleSubmit,
      setError,
    } = useForm<FormType<ISellerOrderFileUploadSubmissionData>>({
      resolver: yupResolver<FormType<ISellerOrderFileUploadSubmissionData>>(
        fileManagerFormSchema,
      ),
    });

    const isSellerOrderDocument = (
      formErrors: FieldErrors<FormType<ISellerOrderFileUploadSubmissionData>>,
    ): formErrors is {
      sellerOrderDocuments?: FieldError;
    } => {
      return 'sellerOrderDocuments' in formErrors;
    };

    const isSellerOrderPhotos = (
      formErrors: FieldErrors<FormType<ISellerOrderFileUploadSubmissionData>>,
    ): formErrors is {
      sellerOrderPhotos?: FieldError;
    } => {
      return 'sellerOrderPhotos' in formErrors;
    };

    const { mutateAsync: photoValidator } = useValidateSellerOrderPhotos(
      orderId || '',
    );

    const { field: filePickerField } = useController({
      name:
        fileVariant === 'documents'
          ? 'sellerOrderDocuments'
          : 'sellerOrderPhotos',
      control,
    });

    const updateValidationStatus = (
      file: File,
      status: string,
      message?: string,
    ) => {
      setValidationStatus(prevValidationStatus => {
        const updatedValidationStatus = new Map(prevValidationStatus);
        updatedValidationStatus.set(getFileFootprint(file), {
          status: status,
          message: message || '',
        } as PhotoValidationStatus);
        return updatedValidationStatus;
      });
    };

    const fileMustBeValidated = (file: File) => {
      return (
        !validationStatus.has(getFileFootprint(file)) &&
        Object.values(FILE_MANAGER_FORMATS_VALIDABLE).includes(file.type)
      );
    };

    const handleValidation = (file: File) => {
      photoValidator({ sellerOrderPhoto: file })
        .then(() => {
          updateValidationStatus(file, 'success');
        })
        .catch(error => {
          let message = 'Error';
          if (error.response.data.sellerOrderPhotos) {
            message = Object.values(
              error.response.data.sellerOrderPhotos[0],
            ).join(', ');
          }
          updateValidationStatus(file, 'error', message);
        });
    };

    useEffect(() => {
      handleFilePickerChange(filePickerField.value as File[], true);
      // This should not be triggered when the filePicker gets new values, this is for validation updates
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validationStatus]);

    const handleFilePickerChange = (
      files: File[] | undefined,
      preserveCurrentErrors = false,
    ) => {
      const errorList = (
        isSellerOrderDocument(formErrors)
          ? formErrors?.sellerOrderDocuments
          : isSellerOrderPhotos(formErrors)
            ? formErrors?.sellerOrderPhotos
            : []
      ) as Record<number, FieldError>;

      const validFiles = files?.filter((file, index) => {
        return (
          Object.values(FILE_MANAGER_SUPPORTED_FORMATS).includes(file.type) &&
          file.size <= FILE_MANAGER_MAX_FILE_SIZE &&
          !(
            validationStatus.has(getFileFootprint(file)) &&
            validationStatus.get(getFileFootprint(file))?.status === 'error'
          ) &&
          !(index in errorList)
        );
      });

      if (FEATURE_FLAGS.IS_PHOTO_CONTENT_VALIDATION_ENABLED_FLAG) {
        validFiles?.forEach(file => {
          if (fileMustBeValidated(file)) {
            updateValidationStatus(file, 'pending');
            handleValidation(file);
          }
        });
      }

      // setInvalidFiles is used to remove files from the input values if they fail their FE or BE validation because
      // in the current version we don't have a remove button if something does not validate there the need to remove it
      const newInvalidFiles =
        files
          ?.map((file, index) => ({ file, originalIndex: index }))
          ?.filter(({ file }) => !validFiles?.includes(file))
          ?.map(({ file, originalIndex }) => {
            const errorMessage = [
              !Object.values(FILE_MANAGER_SUPPORTED_FORMATS).includes(
                file.type,
              ) && fileType,
              file.size > FILE_MANAGER_MAX_FILE_SIZE && fileSize,
              originalIndex in errorList && errorList[originalIndex]?.message,
              validationStatus.has(getFileFootprint(file)) &&
                validationStatus.get(getFileFootprint(file))?.status ===
                  'error' &&
                validationStatus.get(getFileFootprint(file))?.message,
            ]
              .filter(Boolean)
              .join(' ');

            return {
              name: file.name,
              size: file.size,
              type: file.type,
              errorMessage: errorMessage,
            } as IFilePickerFile;
          }) || [];

      setInvalidFiles(currentInvalidFiles =>
        preserveCurrentErrors
          ? [...currentInvalidFiles, ...newInvalidFiles]
          : newInvalidFiles,
      );

      const hasFiles = (validFiles?.length as number) > 0;
      const hasFilesToDelete = deleteList.length > 0;
      const hasPendingValidations = Array.from(validationStatus.values()).some(
        entry => entry.status === 'pending',
      );

      hasUnsavedChanges(hasFiles);
      setShowSubmitButton(
        (hasFiles || hasFilesToDelete) && !hasPendingValidations,
      );

      filePickerField.onChange(validFiles);
    };

    useEffect(() => {
      const hasPendingValidations = Array.from(validationStatus.values()).some(
        entry => entry.status === 'pending',
      );
      const formFieldIsEmpty =
        filePickerField.value === undefined ||
        filePickerField.value.length === 0;
      if (
        (deleteList.length > 0 || !formFieldIsEmpty) &&
        !hasPendingValidations
      ) {
        setShowSubmitButton(true);
      }
      if (deleteList.length === 0 && formFieldIsEmpty) {
        setShowSubmitButton(false);
      }
      // This hook does not need to be triggered when dependencies change as they have their own onChange firing
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteList.length, filePickerField.value]);

    useEffect(() => {
      if (errors !== undefined) {
        Object.keys(errors).map(field => {
          return Object.entries(
            errors[field as ISellerOrderFileUploadSubmissionDataKeys] as {
              [key: number]: ISellerOrderFileUploadErrorObject;
            },
          ).forEach(
            ([fileIndex, fileErrors]: [
              string,
              ISellerOrderFileUploadErrorObject,
            ]) => {
              const fileErrorMessage = [
                fileErrors.fileType && fileErrors.fileType,
                fileErrors.fileSize && fileErrors.fileSize,
              ]
                .filter(Boolean)
                .join(' ');

              setError(
                `${field as ISellerOrderFileUploadSubmissionDataKeys}[${fileIndex}]`,
                {
                  type: 'be-response',
                  message: fileErrorMessage,
                } as FieldError,
              );
            },
          );
        });
        handleFilePickerChange(filePickerField.value as File[]);
      }
      // filePickerField and handleFilePickerChange cannot be added into the deps array
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors, setError]);

    useFormTracking({ formId, formErrors, isSuccess });

    useImperativeHandle(ref, () => ({
      invokeSubmit() {
        handleSubmit(onSubmit)();
      },
    }));

    return (
      <FileManagerWrapper>
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          id={formId}
          name={formId}
        >
          <FileManagerFormWrapper>
            <FilePicker
              id={`${fileVariant}.file-picker-field`}
              formId={formId}
              label={
                fileVariant === 'photos' ? (
                  ''
                ) : (
                  <Translate
                    id={`customer-platform.listing-details.order-${fileVariant}.file-manager.label`}
                  />
                )
              }
              dragAndDropText={dragAndDropText()}
              dragAndDropActiveText={dragAndDropActiveText()}
              deleteList={deleteList}
              onClickDeleteFromRemote={handleDeleteList}
              onClickDownload={onDownloadSingle}
              invalidValues={invalidFiles}
              remoteValues={alreadyUploadedFiles}
              acceptedFormats={Object.keys(FILE_MANAGER_SUPPORTED_FORMATS)}
              value={filePickerField.value as File[]}
              onChange={handleFilePickerChange}
              helperText={
                isSellerOrderDocument(formErrors)
                  ? formErrors?.sellerOrderDocuments?.message
                  : isSellerOrderPhotos(formErrors)
                    ? formErrors?.sellerOrderPhotos?.message
                    : undefined
              }
              errorMessageList={
                isSellerOrderDocument(formErrors)
                  ? formErrors?.sellerOrderDocuments
                  : isSellerOrderPhotos(formErrors)
                    ? formErrors?.sellerOrderPhotos
                    : []
              }
              validationStatusList={validationStatus}
            />
            {globalError ? (
              <FileManagerGlobalErrorWrapper>
                {globalError}
              </FileManagerGlobalErrorWrapper>
            ) : (
              <></>
            )}
            {(showSubmitButton ||
              (alreadyUploadedFiles && alreadyUploadedFiles.length > 0)) && (
              <FileManagerButtonsWrapper>
                {alreadyUploadedFiles && alreadyUploadedFiles.length > 0 && (
                  <Button
                    buttonType="secondary"
                    id={`${formId}.button.download-all`}
                    size="large"
                    onClick={onDownloadAll}
                    isSubmitting={isPending}
                  >
                    <Translate
                      id={`customer-platform.listing-details.order-${fileVariant}.file-manager.button.download-all`}
                    />
                  </Button>
                )}
                {showSubmitButton && (
                  <Button
                    buttonType="primary"
                    id={`${formId}.button.submit`}
                    size="large"
                    type="submit"
                    isSubmitting={isPending}
                  >
                    <Translate
                      id={`customer-platform.listing-details.order-${fileVariant}.file-manager.button.submit`}
                    />
                  </Button>
                )}
              </FileManagerButtonsWrapper>
            )}
          </FileManagerFormWrapper>
        </form>
      </FileManagerWrapper>
    );
  },
);

export default FileManagerForm;
