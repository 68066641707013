import CircularSpinnerWrapper from './CircularSpinner.css';
import { RiLoader4Fill } from '@remixicon/react';

export default function CircularSpinner() {
  return (
    <CircularSpinnerWrapper>
      <RiLoader4Fill />
    </CircularSpinnerWrapper>
  );
}
