import { ReactNode, useEffect, useState } from 'react';
import useAuth from '../AuthContext/useAuth';
import { LoaderWrapper } from './CheckExistingSession.css';
import { CircularProgress } from '@mui/material';

export interface ICheckExistingSession {
  children: ReactNode;
}

export default function CheckExistingSession({
  children,
}: ICheckExistingSession) {
  const { checkSession, checkSessionInProgress } = useAuth();
  const [authenticationYetToInitiate, setAuthenticationYetToInitiate] =
    useState(true);

  useEffect(() => {
    setAuthenticationYetToInitiate(false);
    checkSession && checkSession();
  }, [checkSession, setAuthenticationYetToInitiate]);

  if (authenticationYetToInitiate || checkSessionInProgress) {
    return (
      <LoaderWrapper>
        <CircularProgress size={160} />
      </LoaderWrapper>
    );
  }
  return children;
}
