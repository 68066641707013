import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const FileManagerButtonsWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: '24px',

  '& > .MuiButton-root:last-of-type': {
    flex: '1 0 0',
  },
});

export const FileManagerFormWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

export const FileManagerWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

export const FileManagerGlobalErrorWrapper = styled(MUIBox)(({ theme }) => ({
  color: theme.palette.error[500],
}));
