import { useIntl } from 'react-intl';
import { first } from 'lodash';
import { Translate } from '@frontend/translation';
import { ToastAlert } from '@frontend/ui-elements';
import { LISTING_ORDER_STATUS } from '@frontend/api';
import { formatNumberToUS } from '@frontend/utils';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  InfoLabel,
  InfoItemsWrapper,
  InfoItem,
  MainWrapper,
} from './OrderBasicDetails.css';
import { IOrderBasicDetails, IOrderInfoItem } from './OrderBasicDetails.types';
import { RiInformationLine } from '@remixicon/react';
import { RiArrowUpDownLine } from '@remixicon/react';
import { RiMapPin2Line } from '@remixicon/react';
import { RiCalendar2Line } from '@remixicon/react';
import { RiMoneyDollarCircleLine } from '@remixicon/react';
import { RiFoldersLine } from '@remixicon/react';
import { RiAlertLine } from '@remixicon/react';
import { formatDateWithSpaces } from '@customer-platform-shared/utils/date.utils';
import ConfirmationBanner from './ConfirmationBanner/ConfirmationBanner';

const OrderInfoItem = ({ id, icon, label, value, unit }: IOrderInfoItem) => {
  return (
    <InfoItem data-testid={id}>
      <InfoLabel>
        {icon}
        <Typography variant="p1" component="span">
          {label}
        </Typography>
      </InfoLabel>
      <Typography variant="p3" component="span">
        {value} {unit && ' ' + unit}
      </Typography>
    </InfoItem>
  );
};

export default function OrderBasicDetails({
  order,
  orderBasicDetailsSectionRef,
}: IOrderBasicDetails) {
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));

  const {
    createdDate,
    paymentTerms,
    quantity,
    sellerIncoterms,
    shippingTo,
    value,
  } = order;
  const orderBasicDetailsInfoItems = [
    {
      icon: <RiArrowUpDownLine size={16} />,
      label: (
        <Translate id="customer-platform.listing-details.order-basic-details.final-quantity" />
      ),
      id: 'order-final-quantity',
      value: formatNumberToUS(quantity),
      unit: intl.formatMessage({
        id: 'customer-platform.listing-list.listing-card.weight-unit',
      }),
    },
    {
      icon: <RiCalendar2Line size={16} />,
      label: (
        <Translate id="customer-platform.listing-details.order-basic-details.creation-date" />
      ),
      id: 'order-creation-date',
      value: formatDateWithSpaces(createdDate),
    },
    {
      icon: <RiMapPin2Line size={16} />,
      label: (
        <Translate id="customer-platform.listing-details.order-basic-details.destination-port" />
      ),
      id: 'order-destination-port',
      value: shippingTo,
    },
    {
      icon: <RiFoldersLine size={16} />,
      label: (
        <Translate id="customer-platform.listing-details.order-basic-details.incoterm" />
      ),
      id: 'order-incoterm-information',
      value: intl.formatMessage({
        id: `customer-platform.api.${sellerIncoterms}`,
      }),
    },
    {
      icon: <RiMoneyDollarCircleLine size={16} />,
      label: (
        <Translate id="customer-platform.listing-details.order-basic-details.value" />
      ),
      id: 'order-value',
      value:
        value === 0 ? (
          <Translate id="customer-platform.listing-details.order-basic-details.not-available" />
        ) : (
          formatNumberToUS(value as number)
        ),
      unit:
        value !== 0
          ? intl.formatMessage({
              id: 'customer-platform.listing-list.listing-card.cost-unit',
            })
          : undefined,
    },
    {
      icon: <RiInformationLine size={16} />,
      label: (
        <Translate id="customer-platform.listing-details.order-basic-details.payment-terms" />
      ),
      id: 'order-payment-terms',
      value: paymentTerms,
    },
  ] as IOrderInfoItem[];

  return (
    <MainWrapper ref={orderBasicDetailsSectionRef}>
      <Typography variant={isMobile ? 'h4' : 'h5'}>
        <Translate id="customer-platform.listing-details.order-basic-details.heading" />
      </Typography>
      <InfoItemsWrapper>
        {order.status === first(LISTING_ORDER_STATUS) ? (
          <ToastAlert
            title={
              <Translate id="customer-platform.listing-details.order-basic-details.signing.title" />
            }
            message={
              <Translate id="customer-platform.listing-details.order-basic-details.signing.subtitle" />
            }
            severity="info"
            icon={<RiAlertLine size={24} />}
          />
        ) : (
          orderBasicDetailsInfoItems.map(
            item =>
              item.value && (
                <OrderInfoItem
                  key={item.id + item.value}
                  icon={item.icon}
                  label={item.label}
                  id={item.id}
                  value={item.value}
                  unit={item?.unit}
                />
              ),
          )
        )}
      </InfoItemsWrapper>
      {order.status === 'order.status.confirming_details' && (
        <ConfirmationBanner orderId={order.uuid} />
      )}
    </MainWrapper>
  );
}
