import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const CircularSpinnerWrapper = styled(MUIBox)(() => ({
  position: 'relative',
  display: 'inline-block',
  '& svg': {
    display: 'inline-block',
    verticalAlign: 'middle',
    animation: 'spin 0.5s linear infinite',
    '@keyframes spin': {
      from: { transform: 'rotate(0deg)' },
      to: { transform: 'rotate(360deg)' },
    },
  },
}));

export default CircularSpinnerWrapper;
