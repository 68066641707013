import { ISellerOrderDownloadFileData } from '@frontend/api';
import { trackFormFieldEvent } from '@frontend/tracking';
import { Typography, useTheme } from '@mui/material';
import { RiFileLine } from '@remixicon/react';
import { Fragment, DragEvent, MouseEvent } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Container,
  DropzoneArea,
  DropzoneTextWrapper,
  FileHelperContainer,
  FileSelectorWrapper,
  FileSeparator,
} from './FilePicker.css';
import { IFilePicker, IFilePickerFile } from './FilePicker.types';
import UploadedFile from './UploadedFile/UploadedFile';
import { getFileFootprint } from './FilePicker.utils';

export default function FilePicker({
  dragAndDropText,
  dragAndDropActiveText,
  errorMessageList,
  validationStatusList,
  helperText,
  id = 'file-picker',
  formId = 'unknown-form',
  label,
  onChange,
  required,
  acceptedFormats,
  value: localFiles = [],
  invalidValues: invalidFiles = [],
  remoteValues: remoteFiles = [],
  onClickDeleteFromRemote,
  onClickDownload,
  deleteList,
}: IFilePicker) {
  const theme = useTheme();

  const values = [
    ...localFiles.map(file => ({
      name: file.name,
      size: file.size,
      type: file.type,
    })),
    ...remoteFiles.map(remoteFile => ({
      name:
        remoteFile.title ??
        `${remoteFile.uniqueIdentifier}.${remoteFile.fileType}`,
      type: remoteFile.fileType as string,
      uniqueIdentifier: remoteFile.uniqueIdentifier,
    })),
    ...invalidFiles,
  ] as IFilePickerFile[];

  const handleTrackingEvent = (eventType: 'click' | 'drop') => {
    trackFormFieldEvent({
      formId: formId,
      formField: id,
      formFieldAction: eventType,
    });
  };

  const dropFiles = (files: File[]) => {
    files && onChange([...localFiles, ...Array.from(files)]);
  };

  const deleteLocalFile = (index: number) => {
    onChange(
      localFiles.filter((_, i) => {
        return index !== i;
      }),
    );
  };

  const deleteRemoteFile = (uniqueIdentifier: string) => {
    onClickDeleteFromRemote && onClickDeleteFromRemote(uniqueIdentifier);
  };

  const downloadRemoteFile = (file: ISellerOrderDownloadFileData) => {
    onClickDownload && onClickDownload(file);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      dropFiles(acceptedFiles);
    },
    noClick: false,
    multiple: true,
  });

  return (
    <Container>
      <FileSelectorWrapper>
        {label !== '' && (
          <Typography variant="p1">
            {label}
            {required ? '*' : ''}
          </Typography>
        )}

        <DropzoneArea
          {...getRootProps({
            onClick: (event: MouseEvent<HTMLElement>) =>
              handleTrackingEvent(event.type as 'click'),
            onDrop: (event: DragEvent<HTMLElement>) =>
              handleTrackingEvent(event.type as 'drop'),
          })}
          isDragActive={isDragActive}
        >
          <input
            {...getInputProps()}
            aria-labelledby={`${id}-label`}
            id={id}
            data-testid={id}
            type="file"
            accept={acceptedFormats?.map(format => `.${format}`).join(',')}
          />
          <RiFileLine
            size={24}
            color={
              isDragActive
                ? theme.palette.primary[500]
                : theme.palette.primary[200]
            }
          />
          <DropzoneTextWrapper id={`${id}-label`}>
            <Typography
              color={
                isDragActive
                  ? theme.palette.primary[500]
                  : theme.palette.neutral[400]
              }
              variant={isDragActive ? 'p1' : 'p3'}
            >
              {isDragActive ? dragAndDropActiveText : dragAndDropText}
            </Typography>
          </DropzoneTextWrapper>
        </DropzoneArea>
      </FileSelectorWrapper>

      {helperText && (
        <FileHelperContainer>
          <Typography variant="p3" color={theme.palette.error[500]}>
            {helperText}
          </Typography>
        </FileHelperContainer>
      )}
      {values &&
        values.map((file, index) => {
          return (
            !deleteList?.includes(file.uniqueIdentifier as string) && (
              <Fragment key={index}>
                <UploadedFile
                  file={file}
                  pendingValidation={
                    validationStatusList?.get(getFileFootprint(file as File))
                      ?.status === 'pending'
                  }
                  onClickDelete={
                    'uniqueIdentifier' in file
                      ? () => deleteRemoteFile(file.uniqueIdentifier as string)
                      : () => deleteLocalFile(index)
                  }
                  onClickDownload={
                    'uniqueIdentifier' in file && downloadRemoteFile
                      ? () =>
                          downloadRemoteFile({
                            uniqueIdentifier: file.uniqueIdentifier,
                            fileName: file.name,
                            fileType: file.type,
                          } as ISellerOrderDownloadFileData)
                      : undefined
                  }
                  error={
                    Boolean(errorMessageList && index in errorMessageList) ||
                    Boolean(file.errorMessage)
                  }
                  errorMessage={
                    errorMessageList && index in errorMessageList
                      ? errorMessageList[index]?.message
                      : file.errorMessage
                  }
                />
                {index + 1 < values.length && <FileSeparator />}
              </Fragment>
            )
          );
        })}
    </Container>
  );
}
